html,
body,
#__next {
  height: 100%;
}

body {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", Meiryo,
    メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif;
  color: #111827;
}

input,
textarea,
select {
  &:not([type="radio"]):not([type="checkbox"]) {
    appearance: none;
  }
}

.input-label {
  @apply block font-normal leading-6 mb-1;
}

.input-text {
  @apply block w-full max-w-[600px] rounded-none border border-gray-200 px-3 py-2 placeholder:text-gray-400 focus:outline-none focus:shadow;
}

.select-box {
  @apply block w-full max-w-[600px] rounded-none border py-2 pl-3 pr-7 focus:outline-none focus:shadow;
}

.hr {
  @apply border-t border-gray-200 my-7;
}

.button-action {
  @apply inline-flex items-center justify-center rounded bg-blue-700 text-white px-3 py-2 hover:bg-blue-800 min-w-[120px];
}

.button-simple {
  @apply inline-block px-3 py-2 border border-gray-200 bg-white hover:bg-gray-100 cursor-pointer;
}

.button-destructive {
  @apply inline-block px-3 py-1 border border-gray-200 bg-gray-300 hover:bg-gray-100 cursor-pointer;
}

.h2 {
  @apply text-2xl tracking-wide;
}

.h3 {
  @apply text-xl font-bold;
}

.text {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply bg-gray-100 px-3 py-4 font-bold mt-7 mb-6 text-xl;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.75;
  }

  a {
    color: #1d4ed8;
    text-decoration: underline;
  }

  ul {
    li {
      display: flex;
      position: relative;
      font-size: 1rem;
      line-height: 1.5rem;

      &::before {
        position: relative;
        top: 0.5rem;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.5rem;
        background: #1d4ed8;
        content: "";
      }
    }
  }
}

.swiper {
  overflow: initial !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -35px !important;
}

.swiper-button-prev,
.swiper-button-next {
  &::after {
    font-size: 24px !important;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px !important;
}

.tactic .swiper-button-next,
.tactic .swiper-rtl .swiper-button-prev {
  right: 0;
}

.tactic .swiper-button-prev,
.tactic .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-pagination-bullet {
  background: white !important;
}

.main-visual .swiper-slide {
  transition-duration: 0.5s;
  opacity: 0.6;
}

.main-visual .swiper-slide-active {
  transform: scale(1.1);
  z-index: 1;
  opacity: 1;
}

@media (min-width: 768px) {
  .text {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    p {
      line-height: 1.75;
    }

    ul {
      li {
        font-size: 1.125rem;
        line-height: 1.75rem;

        &::before {
          top: 0.3rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .tactic .swiper-button-prev::after,
  .tactic .swiper-button-next::after {
    font-size: 50px !important;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .swiper-pagination {
    display: none;
  }
}
